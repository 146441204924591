<template>
  <div class="bg-krem q-pt-xl q-px-md" style="height:100vh">
    <div class="column items-center">
      <p class="my-font text-bold text-h5 text-center">List Tamu</p>
      <q-btn
        outline
        rounded
        dense
        icon="get_app"
        label="Download RSVP"
        @click="export_rsvp"
      ></q-btn>
    </div>

    <q-markup-table
      class="q-mt-md"
      wrap-cells
      separator="horizontal"
      style="max-height:calc(100vh - 100px);"
    >
      <thead class="bg-grey-2">
        <tr>
          <th>no</th>
          <th>Nama</th>
          <th>Status Kehadiran</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(val, i) in data.show_rsvp" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ val.nama }}</td>
          <td>{{ val.kehadiran }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <div class="row justify-center">
      <q-pagination
        v-model="data.pagination_rsvp.current"
        :max="data.pagination_rsvp.max"
        input
        input-class="text-orange-10"
        @input="getpage_rsvp"
      ></q-pagination>
    </div>
    <div class="column items-center q-mt-xl">
      <p class="my-font text-bold text-h5 text-center">List Ucapan</p>
      <q-btn
        outline
        rounded
        dense
        icon="get_app"
        label="Download Ucapan"
        @click="export_ucapan"
      ></q-btn>
    </div>

    <q-markup-table
      wrap-cells
      class="q-mt-md"
      separator="horizontal"
      style="max-height:calc(100vh - 100px);"
    >
      <thead class="bg-grey-2">
        <tr>
          <th>no</th>
          <th>Nama</th>
          <th>Ucapan</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(val, i) in data.ucapan" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ val.nama }}</td>
          <td>{{ val.ucapan }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <div class="row justify-center">
      <q-pagination
        v-model="data.pagination_ucapan.current"
        :max="data.pagination_ucapan.max"
        input
        input-class="text-orange-10"
        @input="getpage_ucapan"
      ></q-pagination>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, reactive } from "vue";
import XLSX from "xlsx";
import moment from "moment";
export default {
  setup() {
    let $http = inject("$http");

    const data = reactive({
      rsvp: [],
      ucapan: [],
      id_customer: "0877",
      pagination_rsvp: {
        current: 1,
        max: 99,
      },
      pagination_ucapan: {
        current: 1,
        max: 99,
      },
      show_rsvp: [],
      show_ucapan: [],
    });

    let getrsvp = async () => {
      let resp = await $http.get(`/reservasi/getall/${data.id_customer}`);
      data.rsvp = resp.data;
    };
    let getpage_rsvp = async () => {
      // let pagination = JSON.parse(JSON.stringify(data.pagination));

      data.pagination_rsvp.max = Math.ceil(data.rsvp.length / 10);

      let ret = [];
      for (let i = 0; i < 10; i++) {
        if (data.rsvp[(data.pagination_rsvp.current - 1) * 10 + i]) {
          ret.push(data.rsvp[(data.pagination_rsvp.current - 1) * 10 + i]);
        }
      }

      // data.pagination = pagination;
      data.show_rsvp = ret;
    };
    let export_rsvp = () => {
      let temp = data.rsvp.slice(0);
      for (let item of temp) {
        item.created_at = moment(item.created_at).format("DD MMMM YYYY");
      }

      var ws = XLSX.utils.json_to_sheet(temp);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "RSVP");

      var now = moment().format("DD MMM YYYY");
      XLSX.writeFile(wb, `Data RSVP ${now}.xlsx`);
    };

    let getucapan = async () => {
      let resp = await $http.get(`/ucapan/getall/${data.id_customer}`);
      data.ucapan = resp.data;
    };
    let getpage_ucapan = async () => {
      data.pagination_ucapan.max = Math.ceil(data.rsvp.length / 10);

      let ret = [];
      for (let i = 0; i < 10; i++) {
        if (data.ucapan[(data.pagination_ucapan.current - 1) * 10 + i]) {
          ret.push(data.ucapan[(data.pagination_ucapan.current - 1) * 10 + i]);
        }
      }

      data.show_ucapan = ret;
    };
    let export_ucapan = () => {
      let temp = data.ucapan.slice(0);
      for (let item of temp) {
        item.created_at = moment(item.created_at).format("DD MMMM YYYY");
      }

      var ws = XLSX.utils.json_to_sheet(temp);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "UCAPAN");

      var now = moment().format("DD MMM YYYY");
      XLSX.writeFile(wb, `Data Ucapan ${now}.xlsx`);
    };

    return {
      data,
      getpage_rsvp,
      getrsvp,
      getucapan,
      getpage_ucapan,
      export_rsvp,
      export_ucapan,
    };
  },
  async mounted() {
    await this.getrsvp();
    await this.getucapan();
    await this.getpage_rsvp();
    await this.getpage_ucapan();
  },
};
</script>
