import { render } from "./RSVP.vue?vue&type=template&id=3e9c2188"
import script from "./RSVP.vue?vue&type=script&lang=js"
export * from "./RSVP.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMarkupTable,QPagination});
